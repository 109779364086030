import Vacant from '../assets/common/vacant__ttl.png';
import Inuse from '../assets/common/in-use__ttl.png';
import Standby from '../assets/common/standby__ttl.png';

export default {
  name: 'Usage',
  props: {
    roomVacancy: {
      type: String,
      required: false, // lint error回避のため一旦false, 実際どうかは確認が必要
      default: '',
    },
  },
  render() {
    const vacancy = () => {
      const vacant = {
        cl: 'c-room-now__ttl is-open',
        img: Vacant,
        txt: '空室',
      };
      const standBy = {
        cl: 'c-room-now__ttl is-standby',
        img: Standby,
        txt: 'チェックイン待ち',
      };
      const inUsed = {
        cl: 'c-room-now__ttl is-close',
        img: Inuse,
        txt: '使用中',
      };
      if (this.roomVacancy === 'vacant') return vacant;
      if (this.roomVacancy === 'standBy') return standBy;
      if (this.roomVacancy === 'inUsed') return inUsed;
    };
    return (
      <div class="c-room-now is-active" onTransitionend={() => (this.$store.state.updateAnimation = false)}>
        <div class={vacancy().cl}>
          <img src={vacancy().img} alt="" />
          <p class="c-room-now__sub-ttl">{vacancy().txt}</p>
        </div>
      </div>
    );
  },
};
