import store from '../store';

const list = new Array(24)
  .fill(null)
  .map((n, i) => {
    const view = i.toString().padStart(2, '0');
    return [
      { label: `${view}:00`, value: `${i}:0` },
      { label: `${view}:15`, value: `${i}:15` },
      { label: `${view}:30`, value: `${i}:30` },
      { label: `${view}:45`, value: `${i}:45` },
    ];
  })
  .flat();

export default function (thisStart) {
  const timeCheck = (label) => {
    return label.split(':').join('');
  };
  const nextEvent = store.state.nextEvent.start_time;
  const maxSelectTime = () => {
    const max = store.state.events.list
      .filter((n) => {
        const newDate = n.start_time.replace(/-/g, '/');
        if (new Date(newDate) >= new Date(nextEvent)) return n;
        return false;
      })
      .map((n) => n.start_time.replace(':', '').slice(11, 15));
    return (Number(Math.min(...max)) / 1000) * 60;
  };
  const minSelectTime = () => {
    const now = new Date();
    const hour = now.getHours();
    const mins = `${now.getMinutes().toString().padEnd(2, 0)}`;
    now.setHours(hour);
    now.setMinutes(mins);
    const min = store.state.events.list
      .filter((n) => {
        const newDate = n.end_time.replace(/-/g, '/');
        if (new Date(newDate) >= now) return n;
        return false;
      })
      .map((n) => n.end_time.replace(':', '').slice(11, 15));
    return (Number(Math.min(...min)) / 1000) * 60;
  };
  const sortTime = list.filter((n) => {
    let time = n.label;
    time = (Number(timeCheck(n.label)) / 1000) * 60;
    if (!thisStart) {
      const mins = new Date().getMinutes().toString().padStart(2, 0);
      const currentTime = (Number(`${new Date().getHours()}${mins}`) / 1000) * 60;
      const minSelect = minSelectTime() < currentTime ? minSelectTime() : currentTime;
      const result = nextEvent ? time < maxSelectTime() && time > minSelect : time > currentTime;
      return result;
    } else {
      let startTime = (timeCheck(thisStart) / 1000) * 60;
      if (startTime.length === 2) startTime = startTime.padStart(3, '0').padEnd(4, '0');
      if (startTime.length === 3 && startTime[2] === '0') startTime = startTime.padEnd(4, '0');
      const minSelect = minSelectTime() < startTime ? minSelectTime() : startTime;
      const result = nextEvent ? time <= maxSelectTime() && time > minSelect : time > startTime;
      return result;
    }
  });
  return sortTime;
}
