import TimeVueSelect from './timeVueSelect';
import parseDate from '../utils/parseDate';

const list = new Array(24)
  .fill(null)
  .map((n, i) => {
    const view = i.toString().padStart(2, '0');
    return [
      { label: `${view}:00`, value: `${i}:0` },
      { label: `${view}:15`, value: `${i}:15` },
      { label: `${view}:30`, value: `${i}:30` },
      { label: `${view}:45`, value: `${i}:45` },
    ];
  })
  .flat();

export default {
  name: 'TimeSelect',
  props: {
    type: {
      type: String,
      required: false,
      default: '',
    },
    start: {
      type: String,
      required: false,
      default: '',
    },
    end: {
      type: String,
      required: false,
      default: '',
    },
  },
  render() {
    const onEnd = (e) => {
      this.$store.commit('updateSelectorValue', {
        type: 'end',
        data: { label: e.label, value: e.value },
      });
    };
    const { hours, minutes } = parseDate(new Date());
    const nowHour = `${hours}:${minutes}`;
    const now = new Date();
    const date = new Date(now.setMinutes(Math.round(now.getMinutes() / 15) * 15));
    const time = {
      start: `${date.getHours()}:${date.getMinutes()}`,
      end: `${date.getHours() + 1}:${date.getMinutes()}`,
    };
    const newDateStart = this.start && new Date(this.start.replace(/-/g, '/'));
    const newDateEnd = this.end && new Date(this.end.replace(/-/g, '/'));
    const selectStartVal = {
      start: this.start ? `${this.start.slice(11, 13)}:${newDateStart.getMinutes()}` : time.start,
      end: this.end ? `${this.end.slice(11, 13)}:${newDateEnd.getMinutes()}` : time.end,
    };
    const valueEd = list.find((n) => selectStartVal.end === n.value) || {
      label: selectStartVal.end
        .split(':')
        .map((n) => n.padStart(2, '0'))
        .join(':'),
      value: selectStartVal.end,
    };
    const useEnd = this.$store.state.selectorValueEd?.label;
    !useEnd && this.$store.commit('updateSelectorValue', { type: 'end', data: valueEd });
    return (
      <div>
        <p class="c-modal__ttl u-time__ttl">利用時間を選択してください</p>
        <div class="c-modal__list-box">
          <div class="c-modal__select-time">
            {this.type === 'extensionTime' && (
              <p class="c-modal__select-input c-modal__select-time__in" style="pointer-events: none;">
                {selectStartVal.start
                  .split(':')
                  .map((n) => n.padStart(2, '0'))
                  .join(':')}
              </p>
            )}
            {this.type !== 'extensionTime' && (
              <p class="c-modal__select-input c-modal__select-time__in" style="pointer-events: none;">
                {`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`}
              </p>
            )}
            <span class="c-search-daytime__time-while">-</span>
            <TimeVueSelect class="c-modal__select-input c-modal__select-time__out" onInput={onEnd} value={useEnd} start={nowHour} />
            {this.type === 'extensionTime' && (
              <div class="c-btn c-modal-btn is-close" onClick={this.$listeners.click}>
                延長
              </div>
            )}
            {this.type !== 'extensionTime' && (
              <div class="c-btn c-modal-btn is-open" onClick={this.$listeners.click}>
                利用開始
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
};
