import * as server from '../../network/server';
import getEvent from '../event.get';

export default {
  name: 'CheckOut',
  props: {
    room: {
      type: Object,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { summary: '' };
  },
  render() {
    const events = this.$store.state.nowEvent;
    if (events.calendar) {
      this.summary = events.calendar.summary;
    } else {
      getEvent(this, events);
    }
    const checkOut = () => {
      const inUsed = document.querySelector('.c-room-now');
      !this.$store.state.connectError && this.$modal.show('loadingModal');
      server
        .putSchedules({
          schedule_uuid: events.schedule_uuid,
          rooms: [
            {
              event_id: events.id,
              update: { status: '完了' },
            },
          ],
        })
        .then((r) => (r.success ? Promise.resolve(r) : Promise.reject(r)))
        .then(() => {
          server.getSchedules().then((r) => {
            const getRoomEvent = r.data.find((n) => n.room_id === this.roomId);
            inUsed.classList.remove('is-active');
            if (!inUsed.classList.contains('is-active')) {
              setTimeout(() => {
                inUsed.classList.add('is-active');
                this.$store.state.roomVacant = 'vacant';
                this.$store.commit('updateNowEvent', {
                  type: 'remove',
                  data: '',
                });
                this.$store.commit('updateCheckIn', {
                  type: 'status',
                  data: false,
                });
                this.$store.state.events = getRoomEvent;
              }, 1000);
              this.$modal.hide('loadingModal');
              this.$modal.hide('checkOutModal');
              this.$store.state.reserveFromTablet = false;
            }
          });
        })
        .catch((e) => {
          this.$modal.hide('loadingModal');
          if (e.result.errors) {
            alert('通信エラーが発生しました。');
          }
        });
    };
    const start = this.$store.state.nowEvent.start_time?.slice(11, 16);
    const end = this.$store.state.nowEvent.end_time?.slice(11, 16);
    return (
      <div>
        <div class="p-modal is-open u-check">
          <div class="c-modal-cancel__btn" onClick={this.$listeners.click} />
          <form class="c-modal__content">
            <p class="c-modal__ttl u-check__ttl">下記の予約をチェックアウトしますか？</p>
            <div class="c-modal__list-box u-check__box">
              <div class="c-modal__list-wrap u-check__list">
                <p class="c-modal-room__name">
                  <span class="c-modal-room__sub-name">フロア{this.room.floor}</span>
                  {this.room.name}
                </p>
              </div>
              <div class="c-modal__list-wrap">
                {this.$store.getters.showTitle && <p class="c-modal-meeting__ttl">{this.summary}</p>}
                <p class="c-modal-meeting__time">
                  <span class="c-modal-meeting__time-in">{start}</span>
                  <span class="c-modal-meeting__time-in">〜</span>
                  <span class="c-modal-meeting__time-out">{end}</span>
                </p>
              </div>
            </div>
            <div class="c-btn c-modal-btn is-close" onClick={() => checkOut()}>
              チェックアウト
            </div>
          </form>
        </div>
      </div>
    );
  },
};
