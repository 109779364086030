const resolves = new Set();

const token = new Proxy(
  { value: localStorage.getItem('accessToken') },
  {
    set: (target, key, value) => {
      target[key] = value;
      localStorage.setItem('accessToken', value);
      for (const resolve of resolves) {
        resolve(value);
      }
      resolves.clear();
      return true;
    },
  }
);

export default {
  /**
   * tokenの取得
   * ない場合はPromise
   * @returns {string|Promise<string>}
   */
  get: function () {
    return token.value || new Promise((resolve) => resolves.add(resolve));
  },
  /**
   * tokenの設定
   * tokenが設定された時点でPromiseが全て解決する
   * @param {string} value
   */
  set: function (value) {
    token.value = value;
  },
};
