export default {
  name: 'Facility',
  props: {
    rooms: {
      type: Object,
      required: true,
    },
  },
  render() {
    return (
      <div>
        <ul class="c-room-info__list">
          <li>
            <div class="c-room-info__people">
              <span class="c-room-info__number">{this.rooms.capacity}</span>人
            </div>
            <p class="c-room-info__txt">最大収容人数</p>
          </li>
          <li>
            {this.rooms.tags.map((tagId) => (
              <div class="c-room-info__condition">{this.$store.state.tags.find((tag) => tag.id === tagId).name}</div>
            ))}
          </li>
        </ul>
      </div>
    );
  },
};
