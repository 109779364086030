import Vue from 'vue';
import Vuex from 'vuex';
import { getSchedules } from './network/server';

Vue.use(Vuex);

const today = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
const setTime = () => {
  const start = new Date();
  start.setHours(start.getHours() + 1);
  start.setMinutes(0);
  const end = new Date(start);
  end.setHours(end.getHours() + 1);
  return [start, end];
};

const animation = () => {
  const target = document.querySelector('.c-room-now');
  if (!target) return;
  target.classList.remove('is-active');
  setTimeout(() => {
    target.classList.add('is-active');
  }, 1000);
};

const store = new Vuex.Store({
  state: {
    defaultDate: today(),
    roomId: null,
    defaultRoom: '',
    tags: [],
    roomVacant: '',
    events: [],
    gCalender: [],
    nowEvent: [],
    nextEvent: [],
    staffList: [],
    guestList: [],
    organiserList: [],
    options: [],
    checkIn: {
      defaultTime: setTime(),
      status: false,
    },
    selectorValueEd: [],
    logInStatus: false,
    connectError: false,
    loginError: {},
    startLoading: false,
    loadingCount: false,
    putRoomId: false,
    updateAnimation: false,
    reserveFromTablet: false,
    stockEvents: '',
  },
  mutations: {
    options(state, res) {
      state.options = res.data;
    },
    startLoading(state, data) {
      state.startLoading = data;
    },
    updateConnectError(state, data) {
      state.connectError = data;
    },
    updateLoginError(state, data) {
      if (data === 'succeed') state.loginError = true;
      state.loginError = data;
    },
    updateLoginStatus(state, data) {
      state.loginStatus = data;
    },
    updateSelectorValue(state, { type, data }) {
      if (type === 'end') state.selectorValueEd = data;
      if (type === 'remove') state.selectorValueEd = {};
    },
    updateNextEvent(state, { type, data }) {
      if (type === 'update') state.nextEvent = data[0] || [];
    },
    updateNowEvent(state, { type, data }) {
      if (type === 'update') state.nowEvent = data;
      if (type === 'remove') state.nowEvent = [];
    },
    updateCheckIn(state, { type, data }) {
      if (type === 'status') state.checkIn.status = data;
      if (type === 'remove') {
        state.checkIn.reservedTime = {};
        state.checkIn.checkedInTime = {};
        state.checkIn.status = false;
      }
    },
    usersList(state, { type, data }) {
      const makeList = (type) => {
        const double = type.find((n) => n.email === data.email);
        const capacity = state.defaultRoom.capacity;
        if (!double && type.length < capacity) type.push(data);
      };
      if (type === 'getStaff') {
        state.staffList = data.data;
      }
      if (type === 'guest') makeList(state.guestList);
      if (type === 'organiser') makeList(state.organiserList);
      if (type === 'remove') {
        state.guestList = [];
        state.organiserList = [];
      }
    },
    rooms(state, n) {
      if (state.defaultRoom !== n) state.defaultRoom = n;
    },
    vacancy(state, data) {},
    tags(state, n) {
      if (state.tags !== n.data) state.tags = n.data;
    },
    getReserveDetail(state, { type, data }) {
      if (type === 'put') {
        state.reserveDetail.push(data);
      }
      if (type === 'remove') {
        state.reserveDetail = [];
      }
    },
  },
  getters: {
    getRoomById: (state) => (id) => state.rooms.find((room) => id === room.id),
    getTagNameById: (state) => (id) => state.tags.find((tag) => id === tag.id).name,
    findOptions: (state) => (name) => Array.from(state.options).find((op) => op.name === name),
    showTitle: (state) => {
      const f = state.options.find((op) => op.name === 'タイトルの表示');
      return f ? f.value : false;
    },
    showOwner: (state) => {
      const f = state.options.find((op) => op.name === '主催者の表示');
      return f ? f.value : false;
    },
    showMembers: (state) => {
      const f = state.options.find((op) => op.name === 'メンバーの表示');
      return f ? f.value : false;
    },
    cancelCheckTime: (state) => {
      const f = state.options.find((op) => op.name === '未チェックイン時の自動キャンセル期間');
      return f ? f.value : false;
    },
  },
  actions: {
    loadEvents({ commit }) {
      getSchedules().then((res) => {
        const roomId = Number(sessionStorage.getItem('id'));
        const getRoomEvent = res.data.find((n) => n.room_id === roomId);
        if (JSON.stringify(getRoomEvent.list) !== this.state.stockEvents) {
          this.state.stockEvents = JSON.stringify(getRoomEvent.list);
          this.state.events.list = getRoomEvent.list.filter((n) => n.status !== 'キャンセル');
          if (this.state.reserveFromTablet) {
            this.state.updateAnimation = false;
            return;
          }
          this.state.updateAnimation = true;
        }
        if (this.state.loadingCount) return;
        commit('updateLoginStatus', true);
        commit('updateLoginError', 'succeed');
        commit('startLoading', true);
        this.state.loadingCount = true;
      });
    },
  },
});

setInterval(() => {
  const now = today();
  if (now.getTime() !== store.state.defaultDate.getTime()) {
    Vue.set(store.state, 'defaultDate', now);
  }
}, 60000);

store.watch(
  (s) => s.roomVacant,
  (n, o) => {
    if (n !== o) animation();
  }
);

store.watch(
  (s) => s.events,
  (n, o) => {
    if (n === o) return;
    const now = new Date();
    const statusList = ['予約', '使用中'];
    const events = n.list.filter((event) => statusList.includes(event.status));
    const ev1 = events[0];
    if (ev1) {
      if (ev1.status === '使用中') {
        store.commit('updateNowEvent', { type: 'update', data: ev1 });
        if (events[1]) {
          store.commit('updateNextEvent', { type: 'update', data: events[1] });
        }
        store.state.roomVacant = 'inUsed';
      } else {
        store.commit('updateNowEvent', { type: 'remove' });
        store.commit('updateNextEvent', { type: 'update', data: [ev1] });
        const nextEventStart = new Date(ev1.start_time.replace(/-/g, '/'));
        nextEventStart.setMinutes(nextEventStart.getMinutes() - store.getters.findOptions('チェックイン可能時間').value);
        store.state.roomVacant = now > nextEventStart.getTime() ? 'standBy' : 'vacant';
      }
    } else {
      store.commit('updateNowEvent', { type: 'remove' });
      store.commit('updateNextEvent', { type: 'update', data: [] });
      store.state.roomVacant = 'vacant'
    }
  }
);

if (process.env.NODE_ENV !== 'production') {
  console.log(store.state);
}
export default store;
