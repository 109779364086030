import SortTime from '../utils/sortTime';
import store from '../store';

export default {
  name: 'ReserveBtn',
  props: {
    roomVacancy: {
      type: String,
      required: false,
      default: '',
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      useNow: false,
      disabled: false,
      enableTime: store.getters.findOptions('チェックイン可能時間')?.value,
    };
  },
  mounted() {
    this.$store.watch(
      (s) => s.options,
      () => {
        this.enableTime = store.getters.findOptions('チェックイン可能時間')?.value;
      }
    );
  },
  methods: {
    show(e) {
      const id = e.target.id;
      if (id === 'guestListModal') {
        this.$router.push({ name: 'inviteGuest', query: { id: this.roomId } }).catch((e) => {});
        this.$modal.show('guestListModal');
      }
      if (id === 'checkInModal') this.$modal.show('checkInModal');
      if (id === 'checkOutModal') this.$modal.show('checkOutModal');
      if (id === 'extensionTimeModal') this.$modal.show('extensionTimeModal');
    },
  },
  render() {
    const btn = (usage) => {
      const vacantTxt = {
        ttl: '未予約の方はこちら',
        btn: '今すぐ利用',
        secondTtl: '予約済みの方はこちら',
        secondBtn: 'チェックイン',
      };
      const usedTxt = {
        ttl: '退室される方はこちら',
        btn: 'チェックアウト',
        secondTtl: '予約を延長される方はこちら',
        secondBtn: '延長',
      };
      const standByTxt = {
        ttl: '入室される方はこちら',
        btn: 'チェックイン',
        // secondTtl: 'キャンセルされる方はこちら',
        // secondBtn: '予約をキャンセル'
        // ttlとsecontTtlは逆になる
      };
      if (usage === 'vacant') return vacantTxt;
      if (usage === 'inUsed') return usedTxt;
      if (usage === 'standBy') return standByTxt;
    };
    const list = btn(this.roomVacancy);
    const style = {
      display: 'none',
    };
    const disabled = {
      pointerEvents: 'none',
      background: '#ccc',
    };
    let id = 'guestListModal';
    if (this.roomVacancy === 'standBy') {
      id = 'checkInModal';
    } else if (this.roomVacancy === 'inUsed') {
      id = 'checkOutModal';
    }
    const extensionTime = this.roomVacancy === 'inUsed' ? 'extensionTimeModal' : 'checkInModal';
    const now = (`${new Date().getHours()}${new Date().getMinutes() + 10}` / 1440) * 100;
    const nextEvent = this.$store.state.nextEvent;
    const neTime = nextEvent.length && nextEvent && (Number(nextEvent.start_time.replace(':', '').slice(11, 15)) / 1440) * 100;
    const nowEvent = this.$store.state.nowEvent;
    if (nowEvent.start_time || neTime < now) this.disabled = true;
    if (SortTime().length > 1) this.useNow = true;
    if (this.roomVacancy === 'standBy') this.useNow = true;
    return (
      <div>
        <div class="c-room-now__btn-wrap">
          <div class="c-room-now__btn-box">
            {this.roomVacancy !== 'standBy' && <p class="c-room-now__btn-ttl">{SortTime().length < 3 ? '今すぐ予約は出来ません。' : list.ttl}</p>}
            {this.roomVacancy === 'standBy' && <p class="c-room-now__btn-ttl">入室される方はこちら</p>}
            <p class="c-btn c-room-now__btn" style={!this.useNow && disabled} id={id} onClick={(e) => this.show(e)}>
              {list.btn}
            </p>
          </div>

          <div class="c-room-now__btn-box" style={this.roomVacancy === 'standBy' && style}>
            <p class="c-room-now__btn-ttl">{!this.disabled || !nowEvent.start_time ? `予定の${this.enableTime}分前より可能` : list.secondTtl}</p>
            <p class="c-btn c-room-now__btn" style={(!this.disabled || !nowEvent.start_time) && disabled} id={extensionTime} onClick={(e) => this.show(e)}>
              {list.secondBtn}
            </p>
          </div>
        </div>
      </div>
    );
  },
};
