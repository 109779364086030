import store from '../store';

setInterval(() => {
  const now = new Date().getTime();
  const nEv = store.state.nextEvent;
  const vacancy = store.state.roomVacant;
  if (!store.state.nowEvent.start_time && !store.state.nextEvent.start_time) {
    store.state.roomVacant = 'vacant';
    return;
  }
  if (store.state.nextEvent.start_time) {
    const nEvStandBy = new Date(nEv.start_time.replace(/-/g, '/'));
    const mins = nEvStandBy.getMinutes() - store.getters.findOptions('チェックイン可能時間').value;
    nEvStandBy.setMinutes(mins);
    if (vacancy === 'vacant') {
      if (now > new Date(nEvStandBy).getTime()) {
        // animation()
        store.state.roomVacant = 'standBy';
      }
    }
  }
  const nowEv = store.state.nowEvent;
  const nEvInUsed = nowEv.end_time && new Date(nowEv.end_time.replace(/-/g, '/'));
  if (vacancy === 'inUsed') {
    const mins = nEvInUsed.getMinutes() + store.getters.cancelCheckTime;
    nEvInUsed.setMinutes(mins);
    if (now >= new Date(nEvInUsed).getTime()) {
      store.state.roomVacant = 'vacant';
      store.commit('updateNowEvent', { type: 'remove', data: '' });
    }
  }
}, 3000);

export default function vacancy() {
  const events = store.state.events;
  const nowEvent = store.state.nowEvent;
  if (!store.state.updateAnimation) return;
  if (events.list) {
    events.list.forEach((n) => {
      if (n.status === '使用中') {
        // animation()
        if (store.state.roomVacant !== 'inUsed') store.state.roomVacant = 'inUsed';
        store.commit('updateNowEvent', { type: 'update', data: n });
        store.commit('updateCheckIn', { type: 'status', data: true });
      } else if (n.status === 'キャンセル' || n.status === '完了') {
        if (n.calendar_id === nowEvent.calendar_id) {
          // animation()
          if (store.state.roomVacant !== 'vacant') {
            store.state.roomVacant = 'vacant';
          }
          store.commit('updateNowEvent', { type: 'remove', data: '' });
          store.commit('updateNextEvent', { type: 'update', data: [] });
        }
        store.commit('updateCheckIn', { type: 'status', data: false });
      }
    });
    if (store.state.updateAnimation !== false) store.state.updateAnimation = false;
  }
  if (!nowEvent.length && !store.state.roomVacant) {
    if (store.state.roomVacant !== 'vacant') {
      store.state.roomVacant = 'vacant';
    }
    if (store.state.updateAnimation !== false) store.state.updateAnimation = false;
  }
}
