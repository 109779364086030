import Guest from './guest';
import Organiser from './organiser';

export default {
  name: 'Invitation',
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    time: function () {
      const organiser = this.$store.state.organiserList;
      if (!organiser.length) {
        alert('主催者を追加してください。');
        return;
      }
      this.$router.push({ name: 'timeSelect', query: { id: this.roomId } }).catch(() => {});
    },
  },
  render() {
    const cancelOrganiser = (e) => {
      const email = e.target.dataset.email;
      this.$store.state.organiserList = this.$store.state.organiserList.find((n) => n.email !== email);
    };
    const cancelGuest = (e) => {
      const email = e.target.dataset.email;
      this.$store.state.guestList = this.$store.state.guestList.find((n) => n.email !== email);
    };
    return (
      <div>
        <p class="c-modal__ttl">参加者を追加してください</p>
        <div class="c-modal__list-box">
          <Organiser onClick={cancelOrganiser} />
          <Guest onClick={cancelGuest} />
        </div>
        <p class="c-error__txt">主催者を選択してください。</p>
        <div class="c-btn c-modal-btn is-open" onClick={this.time}>
          次へ
        </div>
      </div>
    );
  },
};
