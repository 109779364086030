// import RouteViews from './routeViews'
import RoomInfo from './views/room/roomInfo';
import Usage from './views/usage';
import Alert from './views/alert';
import Modal from './views/modal/modal';
import Logo from './assets/common/logo__img.png';
import vacancy from './utils/vacancy';

export default {
  name: 'App',
  mounted() {
    setInterval(() => {
      if (this.$store.state.putRoomId) this.$store.dispatch('loadEvents');
    }, 10000);
  },
  render() {
    const login = this.$store.state.loginStatus;
    if (this.$route.query.id) {
      sessionStorage.setItem('id', Number(this.$route.query.id));
    }
    const roomId = Number(sessionStorage.getItem('id'));
    const putRoomId = this.$store.state.putRoomId;
    login && this.$router.push({ query: { id: roomId } }).catch((e) => {});
    const roomVacancy = this.$store.state.roomVacant;
    const room = this.$store.state.defaultRoom;
    if (!roomVacancy) this.$store.state.roomVacant = 'vacant';
    vacancy();
    const signOut = () => {
      this.$store.state.loginStatus = false;
      gapi.auth2.getAuthInstance().signOut();
    };
    return (
      <div id="app" class="class_meeting_room_wrap">
        {!login && !putRoomId && <router-view />}
        {/* <RouteViews /> */}

        {/* 下記以降変更 */}
        <div class="c-logo__img" onClick={signOut}>
          <img src={Logo} alt="社名ロゴ" />
          <span class="app-version">{APP_VERSION}</span>
        </div>
        {login && putRoomId && <RoomInfo roomId={roomId} />}
        {login && putRoomId && (
          <div class="c-room-now__box">
            <Usage roomVacancy={roomVacancy} />
            <Alert roomVacancy={roomVacancy} roomId={roomId} />
          </div>
        )}
        {login && putRoomId && <Modal room={room} roomId={roomId} />}
      </div>
    );
  },
};
