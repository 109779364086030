import axios from 'axios';
import qs from 'qs';
import token from './token';

const request = async (path, method, query) => {
  const accessToken = await token.get();
  const url = method !== 'GET' || !query ? path : `${path}?${qs.stringify(query)}`;
  const data = method !== 'GET' ? query : undefined;
  return axios({
    method,
    url: `${API_SERVER_ADDRESS}/api/v1/${url}`,
    data,
    headers: {
      Authorization: accessToken,
      Accept: 'application/json',
    },
  }).then((r) => r.data);
};

export const getToken = async (code) => {
  return axios({
    method: 'post',
    url: `${API_SERVER_ADDRESS}/api/v1/auth/tablet`,
    data: { code },
    headers: { Accept: 'application/json' },
  }).then((r) => {
    return r.data;
  });
};

export const getRooms = () => request('room/list', 'GET');
export const getTags = () => request('tag/list', 'GET');
export const getStaffs = () => request('staff/list', 'GET');

export const getSchedules = (params) => request('calendars', 'GET', params);
export const postSchedules = (params) => request('calendars', 'POST', params);
export const putSchedules = (params) => request('calendars', 'PUT', params);

export const getHasSchedule = (params) => request('calendars/has', 'GET', params);

export const getOptions = () => request('option/list', 'GET');
