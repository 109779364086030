import * as server from '../../network/server';
import getInputEndTime from '../../functions/getInputEndTime';
import TimeSelect from '../timeSelect';

export default {
  name: 'ExtensionTime',
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  render() {
    const extentPlans = () => {
      const events = this.$store.state.nowEvent;

      try {
        getInputEndTime();
      } catch (e) {
        console.warn(e);
        return alert('値にエラーがあります。');
      }

      if (!events || !events.id) {
        return alert('値にエラーがあります。');
      }

      !this.$store.state.connectError && this.$modal.show('loadingModal');
      server
        .putSchedules({
          schedule_uuid: events.schedule_uuid,
          rooms: [
            {
              event_id: events.id,
              update: {
                end_time: getInputEndTime(),
              },
            },
          ],
        })
        .then((r) => (r.success ? Promise.resolve(r) : Promise.reject(r)))
        .then((r) => {
          server.getSchedules().then((r) => {
            this.$store.state.events = r.data.find((n) => n.room_id === this.roomId);
          });
          this.$modal.hide('loadingModal');
          this.$modal.hide('extensionTimeModal');
        })
        .catch((e) => {
          if (e.message.indexOf('Not available during that time') !== -1) {
            const txt = e.message.slice(e.message.indexOf(':') + 1, e.message.indexOf('}'));
            alert(`指定の時間帯はすでに予約されています。\n${txt}`);
          } else if (e.errors) {
            alert('通信エラーが発生しました。');
          } else {
            alert('延長操作に失敗しました。');
          }
          this.$modal.hide('loadingModal');
        });
    };
    const start = this.$store.state.nowEvent.start_time;
    const end = this.$store.state.nowEvent.end_time;
    return (
      <div>
        <div class="p-modal is-open u-check">
          <div class="c-modal-cancel__btn" onClick={this.$listeners.click} />
          <form class="c-modal__content">
            <div class="c-modal__list-box">
              <div class="c-modal__select-time">
                <TimeSelect onClick={extentPlans} type="extensionTime" start={start} end={end} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  },
};
