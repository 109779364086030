import Vue from 'vue';
import VModal from 'vue-js-modal';
import * as gas from '../../network/server';

Vue.use(VModal);

export default {
  name: 'Login',
  data() {
    return {
      rooms: [],
    };
  },
  created() {
    gas.getRooms().then((r) => (this.rooms = r.data));
  },
  methods: {
    logIn: function () {
      const roomId = this.$el.querySelector('select[name="roomoName"]').value;
      if (!roomId) {
        alert('roomId を指定してください。');
        return;
      }
      const defaultRoom = this.rooms.find((n) => n.id === Number(roomId));
      this.$store.state.roomId = Number(roomId);
      this.$store.commit('rooms', defaultRoom);
      this.$store.dispatch('loadEvents');
      sessionStorage.setItem('id', Number(roomId));
      this.$store.state.putRoomId = true;
    },
  },
  render() {
    const loginStatus = this.$store.state.loginError;
    let errorTxt = '';
    if (loginStatus.message && loginStatus.message.error === 'popup_closed_by_user') {
      errorTxt = '認証に失敗しました。';
    }
    if (!this.rooms) return;
    return (
      <div class="p-login">
        <div class="c-login-box">
          <p class="c-login__ttl">サインインしてください。</p>
          <p>
            会議室ID:
            <select name="roomoName" id="" style="border: none; border-bottom: 2px solid #000; padding: 0 5px 0 10px; font-size: 16px; font-weight: bold;">
              {this.rooms.map((name) => {
                return <option value={name.id}>{name.name}</option>;
              })}
            </select>
          </p>
          {!loginStatus.success && <p class="c-login__error-txt is-error">{errorTxt}</p>}
          <p class="c-login-btn c-input-btn is-active" onClick={this.logIn}>
            会議室へチェックイン
          </p>
        </div>
      </div>
    );
  },
};
