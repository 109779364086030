import * as server from '../../../network/server';
import { registration } from '../../../network/getPostData';
import colors from 'data/colors';
import calendarEvents from '../../../states/calendarEvents';

export default {
  name: 'InvitationIndex',
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  render() {
    const ownerEmail = this.$store.state.organiserList[0];
    const guestList = this.$store.state.guestList.map((n) => n.email);
    const handleClickNow = () => {
      reserveNow().catch((e) => {
        if (typeof e.message === 'string' && e.message.slice(0, e.message.indexOf('[') - 1) === 'Not available during that time') alert('指定した時間はすでに予約されています');
        this.$modal.hide('loadingModal');
        e.errors && alert('通信エラーが発生しました。');
      });
    };
    const reserveNow = async () => {
      this.$store.state.reserveFromTablet = true;
      const randomNum = Math.floor(Math.random() * 10);
      const owner = calendarEvents.get(ownerEmail.email);
      const color = colors[randomNum];
      const members = guestList.map((email) => {
        const ce = calendarEvents.get(email);
        return {
          email: ce.calendarId,
          color_code: ce.color.color,
        };
      });
      const sendData = registration(this.roomId, owner, members, '使用中', color.color);
      this.$modal.show('loadingModal');
      await server.postSchedules(sendData).then((r) => (r.success ? Promise.resolve(r) : Promise.reject(r)));
      const schedules = await server.getSchedules();

      this.$store.state.events = schedules.data.find((n) => n.room_id === this.roomId);
      this.$modal.hide('loadingModal');
      this.$modal.hide('checkInModal');
      this.$modal.hide('guestListModal');
    };
    return (
      <div class="p-modal is-open u-guest">
        <div class="c-modal-cancel__btn" onClick={this.$listeners.click} />
        <form class="c-modal__content">
          <router-view name="guestInvitation" roomId={this.roomId} onClick={handleClickNow} label={colors} />
        </form>
      </div>
    );
  },
};
