export default {
  name: 'Event',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  render() {
    const event = this.event;
    const newDateSt = event.start_time.replace(/-/g, '/');
    const newDateEd = event.end_time.replace(/-/g, '/');
    const start = new Date(newDateSt);
    const end = new Date(newDateEd);
    const startMin = start.getDate() < new Date().getDate() ? 0 : start.getHours() * 60 + start.getMinutes();
    const endMin = Math.max(end.getDate() > new Date().getDate() ? 1440 : end.getHours() * 60 + end.getMinutes(), startMin + 30);
    const style = {
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      left: 'calc(' + (startMin / 1440) * 100 + '% - 1px)',
      right: 'calc(' + (1 - endMin / 1440) * 100 + '% - 1px)',
      height: '70px',
      marginTop: '-33px',
      background: event.color,
      pointerEvents: 'none',
      borderRadius: '2px',
      borderLeft: '1px solid #fff',
      borderRight: '1px solid #fff',
    };

    return <div style={style} data-start={event.start_time} data-end={event.end_time} />;
  },
};
