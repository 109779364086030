import { dragscroll } from 'vue-dragscroll';
import Event from './event';

export default {
  name: 'Schedules',
  directives: {
    dragscroll: dragscroll,
  },
  mounted() {
    this.dragScroll();
    setInterval(() => {
      this.$forceUpdate();
    }, 10000);
  },
  methods: {
    dragScroll: function () {
      const scroll = {
        select: document.querySelector('.js_vue_drag_scroll_wrap'),
        width: document.querySelector('.c-shedule__table-box').clientWidth,
        length: document.querySelector('.c-shedule__table-box dl').childElementCount,
      };
      const maxScroll = scroll.select.scrollWidth - scroll.select.clientWidth;
      scroll.select.scrollLeft = Math.min(Math.round((scroll.width / scroll.length) * 8), maxScroll);
    },
  },
  render() {
    return (
      <div class="js_vue_drag_scroll_wrap c-calender__vue_drag" v-dragscroll>
        <div class="c-shedule__table-wrap js_vue_drag_scroll_child" style="position: relative;">
          <div class="c-shedule__table-box">
            {Array(24)
              .fill(null)
              .map((empty, index) => {
                return (
                  <dl class="c-shedule__table">
                    <dt>
                      <p class="c-shedule__table-time">{index}:00</p>
                    </dt>
                    <dd>
                      <div class="c-shedule__table-content" />
                    </dd>
                  </dl>
                );
              })}
          </div>
          {this.$store.state.events.list
            .filter((event) => event.status !== 'キャンセル')
            .map((event) => (
              <Event event={event} />
            ))}
        </div>
      </div>
    );
  },
};
