import store from '../store';
import format from '../utils/formatDate';

const timeChecker = () => {
  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const days = today.getDate().toString().padStart(2, '0');
  const ed = store.state.selectorValueEd.label.split(':').map((n) => n.padStart(2, '0'));
  const evEd = `${today.getFullYear()}-${month}-${days} ${ed[0]}:${ed[1]}:00`;
  return [evEd];
};

export function extension(id) {
  try {
    return {
      rooms: [
        {
          event_id: id,
          update: {
            end_time: timeChecker()[0],
          },
        },
      ],
    };
  } catch (e) {
    console.warn(e);
    alert('値にエラーがあります。');
  }
}

export function registration(id, owner, members, status, color) {
  try {
    return {
      rooms: [
        {
          room_id: id,
          start_time: format(new Date()),
          end_time: timeChecker()[0],
          color: color,
        },
      ],
      owner: {
        email: owner.calendarId,
        color_code: owner.color.color,
      },
      members,
      status,
      summary: '会議室予約',
      description: '詳細無し',
      force: true,
    };
  } catch (e) {
    alert('値にエラーがあります。');
  }
}
