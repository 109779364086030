import * as server from '../../network/server';

export default {
  name: 'CheckIn',
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { summary: '' };
  },
  render() {
    const checkIn = () => {
      const events = this.$store.state.nextEvent;
      if (events.calendar) {
        this.summary = events.calendar.summary;
      }
      const inUsed = document.querySelector('.c-room-now');
      !this.$store.state.connectError && this.$modal.show('loadingModal');
      server
        .putSchedules({
          schedule_uuid: events.schedule_uuid,
          rooms: [
            {
              event_id: events.id,
              update: { status: '使用中' },
            },
          ],
        })
        .then((r) => (r.success ? Promise.resolve(r) : Promise.reject(r)))
        .then(() => {
          inUsed.classList.remove('is-active');
          if (!inUsed.classList.contains('is-active')) {
            setTimeout(() => {
              events.status = '使用中';
              const target = this.$store.state.events.list.find((ev) => ev.id === events.id);
              if (target) {
                target.status = '使用中';
              }
              inUsed.classList.add('is-active');
              this.$store.state.roomVacant = 'inUsed';
              this.$store.commit('updateNowEvent', {
                type: 'update',
                data: events,
              });
              this.$store.state.nextEvent = [];
              this.$store.commit('updateCheckIn', {
                type: 'status',
                data: true,
              });
            }, 1500);
            this.$modal.hide('loadingModal');
            this.$modal.hide('checkInModal');
            this.$store.commit('updateNextEvent', { type: 'update', data: [] });
          }
        })
        .catch((e) => {
          this.$modal.hide('loadingModal');
          if (e.result.errors) {
            alert('通信エラーが発生しました。');
          }
        });
    };
    const checkInStTime = this.$store.state.nextEvent.start_time.slice(11, 16);
    const checkInEndtTime = this.$store.state.nextEvent.end_time.slice(11, 16);
    return (
      <div>
        <div class="p-modal is-open u-check">
          <div class="c-modal-cancel__btn" onClick={this.$listeners.click} />
          <form class="c-modal__content">
            <p class="c-modal__ttl u-check__ttl">下記の予約にチェックインしますか？</p>
            <div class="c-modal__list-box u-check__box">
              <div class="c-modal__list-wrap u-check__list">
                <p class="c-modal-room__name">
                  <span class="c-modal-room__sub-name">フロア{this.room.floor}</span>
                  {this.room.name}
                </p>
              </div>
              <div class="c-modal__list-wrap">
                {this.$store.getters.showTitle && <p class="c-modal-meeting__ttl">{this.summary}</p>}
                <p class="c-modal-meeting__time">
                  <span class="c-modal-meeting__time-in">{checkInStTime}</span>
                  <span class="c-modal-meeting__time-in">〜</span>
                  <span class="c-modal-meeting__time-out">{checkInEndtTime}</span>
                </p>
              </div>
            </div>
            <div class="c-btn c-modal-btn is-standby" onClick={() => checkIn()}>
              チェックイン
            </div>
          </form>
        </div>
      </div>
    );
  },
};
