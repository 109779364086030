import SelectGuestBox from 'components/selectGuestBox';
import GuestList from './guestList';

export default {
  name: 'Organiser',
  render() {
    const handleInput = (user) => {
      this.$store.commit('usersList', { type: 'organiser', data: user });
    };
    const wrap = this.$store.state.organiserList;
    return (
      <div class="c-modal__list-wrap">
        <dl class="c-modal__list">
          <dt>
            <SelectGuestBox insert={true} ref="select" class="c-modal__input" style={wrap.length && 'pointer-events: none;'} placeholder="主催者を選択" onInput={handleInput} />
          </dt>
          <dd>
            <ul class="c-modal__select-list">
              {this.$store.state.organiserList.map((guest) => {
                return <GuestList guest={guest} onClick={this.$listeners.click} />;
              })}
            </ul>
          </dd>
        </dl>
      </div>
    );
  },
};
