import ReserveBtn from './reserveBtn';
import StandbyAlert from './standbyAlert';

export default {
  name: 'Alert',
  props: {
    roomVacancy: {
      type: String,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  render() {
    const status = this.roomVacancy;
    const nextEvent = this.$store.state.nextEvent;
    let vClass = 'is-open';
    if (status === 'standBy') vClass = 'is-standby';
    if (status === 'inUsed') vClass = 'is-close';
    return (
      <div class="c-room-now__sub">
        {status === 'vacant' && <p class="c-room-now__info">会議室が使用可能です。</p>}
        {status === 'standBy' && nextEvent.start_time && <StandbyAlert />}
        {status === 'inUsed' && (
          <p class="c-room-now__info">
            会議終了後は必ず
            <br />
            チェックアウトしてください。
          </p>
        )}
        <ReserveBtn class={vClass} roomVacancy={status} roomId={this.roomId} />
      </div>
    );
  },
};
