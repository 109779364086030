export default {
  name: 'Alert',
  render() {
    const nextEvent = this.$store.state.nextEvent;
    const autoCancelTime = this.$store.getters.cancelCheckTime;
    const now = new Date().getTime();
    const nEvStandBy = nextEvent.start_time && new Date(nextEvent.start_time.replace(/-/g, '/'));
    const nEvStandByMins = nEvStandBy.getMinutes() + autoCancelTime;
    nEvStandBy.setMinutes(nEvStandByMins);
    let setTime = new Date(new Date(nEvStandBy).getTime() - now).getMinutes();
    const countDown = setInterval(() => {
      if (nextEvent !== this.$store.state.nextEvent) {
        clearInterval(countDown);
      }
      setTime--;
      if (setTime < 0) {
        this.$store.state.roomVacant = 'vacant';
        this.$store.commit('updateNextEvent', { type: 'update', data: [] });
        setTime = 0;
        clearInterval(countDown);
      }
      if (this.$el.querySelector('.c-room-now__info_time')) this.$el.querySelector('.c-room-now__info_time').innerHTML = setTime;
    }, 60 * 1000);
    return (
      <p class="c-room-now__info">
        予定時刻になりました。
        <br />
        あと<span class="c-room-now__info_time">{setTime}</span>
        分で自動でキャンセルになります。
      </p>
    );
  },
};
