import VueRouter from 'vue-router';
import Invitation from '../views/modal/invitation/invitation';
import TimeSelect from '../views/timeSelect.jsx';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: require('../components/oauthLogin/login.jsx').default,
  },
  {
    path: '/inviteGuest',
    name: 'inviteGuest',
    components: { guestInvitation: Invitation },
  },
  {
    path: '/timeSelect',
    name: 'timeSelect',
    components: { guestInvitation: TimeSelect },
  },
  {
    path: '/roomid',
    name: 'roomId',
    component: require('../components/oauthLogin/roomId.jsx').default,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export { router, VueRouter };
