import Schedules from './schedules';

const dayStrings = ['日', '月', '火', '水', '木', '金', '土'];

export default {
  name: 'Date',
  data() {
    return {
      today: new Date(),
      timer: null,
    };
  },
  computed: {
    year() {
      return this.$store.state.defaultDate.getFullYear();
    },
    month() {
      return this.$store.state.defaultDate.getMonth() + 1;
    },
    date() {
      return this.$store.state.defaultDate.getDate();
    },
    day() {
      return dayStrings[this.$store.state.defaultDate.getDay()];
    },
  },
  render() {
    return (
      <div>
        <dl class="c-shedule__box">
          <dt>
            <p class="c-shedule__YM">
              {this.year}年{this.month}月
            </p>
            <p class="c-shedule__DW">
              {this.date}日({this.day})
            </p>
          </dt>
          <dd>
            <Schedules />
          </dd>
        </dl>
      </div>
    );
  },
};
