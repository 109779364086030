import getEvent from 'views/event.get';

export default {
  name: 'PlansTime',
  props: {
    thisEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { summary: '', owner: '' };
  },
  render() {
    const start = this.thisEvent.start_time.slice(11, 16);
    const end = this.thisEvent.end_time.slice(11, 16);
    if (this.thisEvent.calendar) {
      this.summary = this.thisEvent.calendar.summary;
      this.owner = this.thisEvent.calendar.creator.email;
    } else {
      getEvent(this, this.thisEvent).catch((e) => {
        console.warn(e);
      });
    }

    const searchedListOwner = this.$store.state.staffList.find((staff) => staff.email === this.owner);
    return (
      <div class="c-shedule__info-cont">
        <div class="c-shedule__group__wrap">
          <div class="c-shedule__info-time">
            <p class="c-shedule__info-time__in">{start}</p>
            <span>〜</span>
            <p class="c-shedule__info-time__out">{end}</p>
          </div>
          {this.$store.getters.showTitle && <p class="c-shedule__info-ttl">{this.summary}</p>}

          {this.$store.getters.showOwner && <p class="c-shedule__group-ttl">{searchedListOwner ? searchedListOwner.name : this.owner}</p>}
          {this.$store.getters.showMembers &&
            this.thisEvent.users.length &&
            this.thisEvent.users
              .filter((user) => this.$store.state.defaultRoom.resource_id !== user && user.email !== this.owner)
              .map((user) => {
                const searchedList = this.$store.state.staffList.find((staff) => staff.email === user.email);
                return <p class="c-shedule__group-ttl">{searchedList ? searchedList.name : user.email}</p>;
              })}
        </div>
      </div>
    );
  },
};
