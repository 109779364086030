import Vue from 'vue';
import { signIn } from '../../network/init';
import Loading from '../../views/modal/loading.jsx';
import VModal from 'vue-js-modal';

Vue.use(VModal);

export default {
  name: 'Login',
  methods: {
    logIn: function () {
      signIn();
      this.$modal.show('loadingModal');
    },
  },
  render() {
    return (
      <div class="p-login">
        <div class="c-login-box">
          <p class="c-login__ttl">サインインしてください。</p>
          <p class="c-login-btn c-input-btn is-active" onClick={this.logIn}>
            サインイン
          </p>
          <modal class="p-modal__mail-info p-modal__loading" name="loadingModal" width="350px" height="150px" pivotX={0.5} pivotY={0}>
            <Loading />
          </modal>
        </div>
      </div>
    );
  },
};
