import VueSelect from 'vue-select';
import sortTime from '../utils/sortTime';

export default {
  name: 'TimeVueSelect',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    start: {
      type: String,
      required: false,
      default: '',
    },
  },
  render() {
    return <VueSelect options={sortTime(this.start)} searchable={false} value={this.value} onInput={this.$listeners.input} clearable={false} />;
  },
};
