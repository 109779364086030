import VueSelect from 'vue-select';

export default {
  name: 'SelectGuestBox',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    insert: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getOptions() {
      if (this.insert) {
        return this.$store.state.staffList
          .filter((staff) => staff.auth)
          .map((staff) => {
            return { email: staff.email, name: staff.name };
          });
      } else {
        return this.$store.state.staffList.map((staff) => {
          return { email: staff.email, name: staff.name };
        });
      }
    },
  },
  render() {
    const options = this.getOptions();
    const search = (options, search) => {
      if (search.length < 1) {
        this.$refs.wrapper.classList.remove('none');
        return options;
      }
      const searchResult = options.filter((option) => option.email.indexOf(search) !== -1 || option.name.indexOf(search) !== -1);
      if (searchResult.length !== 0) {
        this.$refs.wrapper.classList.remove('none');
        return searchResult;
      }
      const res = search.match(/.+@.+\..+/) !== null ? [{ email: search, name: search }] : [];
      res.length === 0 ? this.$refs.wrapper.classList.add('none') : this.$refs.wrapper.classList.remove('none');
      return res;
    };

    const scopedSlots = {
      option: ({ email, name }) => (
        <div>
          <div class="c-guest-join__box">
            <p class="c-guest-join__name">{name}</p>
            <p class="c-guest-join__mail">{email}</p>
          </div>
        </div>
      ),
    };

    return (
      <div>
        <div ref="wrapper">
          <VueSelect value="" placeholder={this.placeholder} options={options} onInput={this.$listeners.input} filter={search} label="name" scopedSlots={scopedSlots}>
            <div slot="no-options">
              <p>
                候補が見つかりませんでした。
                <br />
                候補以外の対象を指定する場合はE-mailアドレスを入力してください。
              </p>
            </div>
          </VueSelect>
        </div>
      </div>
    );
  },
};
