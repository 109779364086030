import store from '../store';
import * as gas from './server';
import { router } from '../router/index';
import token from 'network/token';
import { getToken } from './server';

const gasConnection = async () => {
  gas.getTags().then((r) => store.commit('tags', r));
  gas.getStaffs().then((r) => store.commit('usersList', { type: 'getStaff', data: r }));
  gas.getOptions().then((res) => store.commit('options', res));

  if (typeof store.state.roomId === 'number') {
    gas.getRooms().then((r) => {
      const defaultRoom = r.data.find((n) => n.id === store.state.roomId);
      store.commit('rooms', defaultRoom);
    });
  }

  setTimeout(gasConnection, 60000);
};

const config = () => ({
  client_id: `${GOOGLE_CLIENT_ID}`,
  scope: 'profile email https://www.googleapis.com/auth/calendar.events',
});

export async function signIn() {
  const auth2 = gapi.auth2.getAuthInstance();
  if (auth2.isSignedIn.get() && localStorage.getItem('accessToken')) return;

  try {
    const { code } = await auth2.grantOfflineAccess(config());
    const res = await getToken(code);
    token.set(res.accessToken);
    router.push({ name: 'roomId' }).catch((e) => {});
  } catch (e) {
    store.commit('updateLoginError', {
      success: false,
      message: e.message,
    });
  }
}

export function init() {
  gapi.load('client:auth2', function () {
    gapi.auth2.init(config()).then(() => {
      const auth2 = gapi.auth2.getAuthInstance();
      if (!localStorage.getItem('accessToken') || !auth2.isSignedIn.get()) {
        router.push({ name: 'login' }).catch((e) => {});
        if (auth2.isSignedIn.get()) gapi.auth2.getAuthInstance().signOut();
        return auth2.isSignedIn.listen((bool) => {
          if (!bool) return;
          router.push({ name: 'roomId' }).catch((e) => {});
          gasConnection();
        });
      }
      router.push({ name: 'roomId' }).catch((e) => {});
      return auth2.then((r) => {
        gasConnection();
      });
    });
  });
}
