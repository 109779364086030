export default {
  name: 'ReserveTime',
  render() {
    return (
      <div>
        <div class="p-modal is-open">
          <div class="c-modal-cancel__btn" onClick={this.$listeners.click} />
          <form class="c-modal__content">
            <p class="c-modal__ttl u-time__ttl">利用時間を選択してください</p>
            <div class="c-modal__list-box">
              <div class="c-modal__select-time">
                <input type="text" class="c-modal__select-input c-modal__select-time__in" value="午後1:30" />
                <span class="c-modal__select-time__while">〜</span>
                <input type="text" class="c-modal__select-input c-modal__select-time__out" value="午後1:45" />
              </div>
            </div>
            <p class="c-error__txt">すでに予約されています。</p>
            <p class="c-error__txt">時間が重複しています。</p>
            <div class="c-btn c-modal-btn is-open" style="right: 150px;">
              利用開始
            </div>
            <div class="c-btn c-modal-btn is-close">延&nbsp;長</div>
          </form>
        </div>
      </div>
    );
  },
};
