import Facility from './facility';
import Date from './date';
import Plans from './plans';

export default {
  name: 'RoomInfo',
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  render() {
    const roomName = this.$store.state.defaultRoom;
    return (
      <div class="p-room-info__wrap">
        <div class="c-room-info__box">
          <p class="c-room-info__ttl">
            <span class="c-room-info__sub-ttl">フロア{roomName.floor}</span>
            {roomName.name}
          </p>
          <Facility rooms={roomName} />
        </div>
        <div class="p-shedule__wrap">
          <Date />
          <Plans roomId={this.roomId} />
        </div>
      </div>
    );
  },
};
