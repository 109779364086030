import './sass/common.scss';
import './style.css';
import './registration';
import Vue from 'vue';
import { VueRouter, router } from './router/index';
import ViewIndex from './mainView';
import VModal from 'vue-js-modal';
import store from './store';
import { init } from './network/init';

Vue.use(VueRouter);
Vue.use(VModal);

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: (h) => h(ViewIndex),
});

init();

document.addEventListener(
  'touchstart',
  (e) => {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  },
  true
);

let lastTouch = 0;
document.addEventListener(
  'touchend',
  (e) => {
    const now = window.performance.now();
    if (now - lastTouch <= 500) {
      e.preventDefault();
    }
    lastTouch = now;
  },
  true
);
