import CalendarEvent from 'classes/CalendarEvent';
import colors from 'data/colors';
import store from 'store';

export default {
  calendarEvents: {},
  get(calendarId, name = null) {
    if (!name) {
      const staff = store.state.staffList.find((staff) => staff.email === calendarId);
      if (staff) name = staff.name;
    }

    if (!this.calendarEvents[calendarId]) {
      const ce = new CalendarEvent(calendarId, name);
      ce.color = colors[Object.keys(this.calendarEvents).length % colors.length];
      this.calendarEvents[calendarId] = ce;
    }
    return this.calendarEvents[calendarId];
  },
  clear() {
    this.calendarEvents = {};
  },
};
