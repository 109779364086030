import * as calendar from '../network/calender';

export default async function (_this, ev) {
  const resourceId = _this.$store.state.defaultRoom.resource_id;
  const eventId = ev.calendar_id;
  const r = await calendar.getResourceCalenderDetail(resourceId, eventId);
  if (!r.success) return;
  ev.calendar = r.calender.result;
  _this.summary = ev.calendar.summary;
  if (_this.owner !== undefined) {
    _this.owner = ev.calendar.creator.email;
  }
  _this.$forceUpdate();
}
