import GuestList from './invitation/index';
import CheckIn from './checkIn';
import CheckOut from './checkOut';
import ReserveTime from './reserveTime';
import ExtensionTime from './extensionTime';
import Loading from './loading.jsx';
import calendarEvents from '../../states/calendarEvents';

export default {
  name: 'ModalIndex',
  props: {
    room: {
      type: Object,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    routerReset(e) {
      if (e.name === 'guestListModal') history.pushState(null, null, `/?id=${this.roomId}`);
    },
    guestReset() {
      this.$store.commit('usersList', { type: 'remove' });
    },
    selectorValueReset() {
      this.$store.commit('updateSelectorValue', { type: 'remove' });
    },
  },
  render() {
    const hideGuestModal = () => this.$modal.hide('guestListModal');
    const hideCheckInModal = () => this.$modal.hide('checkInModal');
    const hideCheckOutModal = () => this.$modal.hide('checkOutModal');
    const hideReserveTimeModal = () => this.$modal.hide('reserveTimeModal');
    const hideExtensionTimeModal = () => this.$modal.hide('extensionTimeModal');
    return (
      <div>
        <modal
          class="p-modal__mail-info"
          name="extensionTimeModal"
          // onBefore-close={() => this.$store.commit('resetModal')}
          width="100%"
          height="auto"
          pivotX={0}
          pivotY={0}
          clickToClose={false}
        >
          <ExtensionTime onClick={hideExtensionTimeModal} roomId={this.roomId} />
        </modal>
        <modal
          class="p-modal__mail-info"
          name="reserveTimeModal"
          // onBefore-close={() => this.$store.commit('resetModal')}
          width="100%"
          height="auto"
          pivotX={0}
          pivotY={0}
          clickToClose={false}
        >
          <ReserveTime onClick={hideReserveTimeModal} />
        </modal>
        <modal
          class="p-modal__mail-info"
          name="checkOutModal"
          // onBefore-close={() => this.$store.commit('resetModal')}
          width="100%"
          height="auto"
          pivotX={0}
          pivotY={0}
          clickToClose={false}
        >
          <CheckOut onClick={hideCheckOutModal} roomId={this.roomId} room={this.room} />
        </modal>
        <modal
          class="p-modal__mail-info"
          name="checkInModal"
          // onBefore-close={() => this.$store.commit('resetModal')}
          width="100%"
          height="auto"
          pivotX={0}
          pivotY={0}
          clickToClose={false}
        >
          <CheckIn onClick={hideCheckInModal} room={this.room} />
        </modal>
        <modal
          class="p-modal__mail-info"
          name="guestListModal"
          onBefore-close={(e) => {
            this.routerReset(e);
            this.guestReset();
            this.selectorValueReset();
            calendarEvents.clear();
          }}
          width="100%"
          height="auto"
          pivotX={0}
          pivotY={0}
          clickToClose={false}
        >
          <GuestList onClick={hideGuestModal} roomId={this.roomId} />
        </modal>
        <modal
          class="p-modal__mail-info p-modal__loading"
          name="loadingModal"
          // onBefore-close={(e) => {
          //   this.routerReset(e)
          //   this.guestReset()
          //   this.selectorValueReset()
          // }
          // }
          width="350px"
          height="150px"
          pivotX={0.5}
          pivotY={0}
          clickToClose={false}
        >
          <Loading />
        </modal>
      </div>
    );
  },
};
