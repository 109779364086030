export const insertCalendarEvent = async (calendarId, params) => {
  if (!gapi.client.calendar) {
    await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
  }

  const start = typeof params.start === 'string' ? params.start.split('-').join('/') : params.start;
  const end = typeof params.end === 'string' ? params.end.split('-').join('/') : params.end;

  return gapi.client.calendar.events.insert({
    calendarId,
    resource: {
      ...params,
      start: {
        dateTime: new Date(start).toISOString(),
      },
      end: {
        dateTime: new Date(end).toISOString(),
      },
    },
  });
};

export const moveCalendarEvent = async (params) => {
  return gapi.client.calendar.events.move({
    calendarId: params[0].email,
    eventId: params[1],
    destination: params[2],
  });
};

export const getResourceCalenderDetail = async (calendarId, eventId) => {
  try {
    if (!gapi.client.calendar) {
      await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
    }
    const calender = await gapi.client.calendar.events.get({
      calendarId: calendarId,
      eventId: eventId,
    });
    return {
      success: true,
      calender,
    };
  } catch (err) {
    return {
      success: false,
      message: err,
      error: 'unauthorized',
    };
  }
};

export const getCalendarEventList = async (params) => {
  try {
    if (!gapi.client.calendar) {
      await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
    }
    return await gapi.client.calendar.events.list(params);
  } catch (err) {
    return {
      success: false,
      message: err,
      error: 'unauthorized',
    };
  }
};

export const getCalendarEventListAll = (params, items = []) => {
  return getCalendarEventList(params).then((res) => {
    if (res.error) {
      return Promise.reject(res);
    }

    items.push(...res.result.items);

    if (res.result.nextPageToken) {
      const nextParams = { ...params, pageToken: res.nextPageToken };
      params.syncToken && delete params.syncToken;
      return getCalendarEventListAll(nextParams, items);
    }

    return {
      nextSyncToken: res.result.nextSyncToken,
      items,
    };
  });
};
