import calendarEvents from '../../../states/calendarEvents';

export default {
  name: 'GuestList',
  props: {
    guest: {
      type: Object,
      required: true,
    },
  },
  render() {
    const ce = calendarEvents.get(this.guest.email);
    return (
      <li data-email={this.guest.email}>
        <span class="c-label c-select__color-label" style={{ background: ce.color.color }} />
        <p class="c-select__name">{this.guest.name}</p>
        <p class="c-select__cancel-btn" data-email={this.guest.email} data-listRemove={true} onClick={this.$listeners.click.fns} />
      </li>
    );
  },
};
