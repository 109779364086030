export default {
  name: 'Loading',
  render() {
    return (
      <div class="loading">
        <span>
          現在通信を行っております。
          <br />
          少々お待ちください。
        </span>
      </div>
    );
  },
};
