import { getCalendarEventListAll } from 'network/calender';
import { getHasSchedule } from 'network/server';
import toQueryString from 'utils/toQueryString';

export default class CalendarEvent {
  constructor(calendarId, name = null) {
    this.events = [];
    this.error = false;
    this.calendarId = calendarId;
    this.name = name || calendarId;
  }

  async async() {
    const params = { calendarId: this.calendarId, maxResults: 2500 };
    if (this.nextSyncToken) params.syncToken = this.nextSyncToken;

    try {
      const res = await getCalendarEventListAll(params);
      this.nextSyncToken = res.nextSyncToken;
      res.items.forEach((item) => {
        const index = this.events.findIndex((ev) => ev.id === item.id);
        if (index !== -1) this.events.splice(index, 1);
      });
      this.events.push(
        ...res.items
          .filter((item) => item.start && (item.start.date || item.start.dateTime) && item.end && (item.end.date || item.end.dateTime) && item.status !== 'cancelled')
          .map((item) => {
            item.start = new Date(item.start.date || item.start.dateTime);
            item.end = new Date(item.end.date || item.end.dateTime);
            return item;
          })
      );
      return this.events;
    } catch (e) {
      this.error = true;
      return Promise.reject(e);
    }
  }

  /**
   * 予約システム上に予約があるか
   * @param {Date} start
   * @param {Date} end
   * @returns {Promise<{has:boolean}>}
   */
  hasSchedule(start, end) {
    return getHasSchedule({
      email: this.calendarId,
      start: toQueryString(start),
      end: toQueryString(end),
    });
  }

  /**
   * 予約システム上にcalendarIdを除く予約があるか
   * @param {Date} start
   * @param {Date} end
   * @param {string} scheduleId
   * @returns {Promise<{has:boolean}>}
   */
  hasScheduleExcludeId(start, end, scheduleId) {
    return getHasSchedule({
      email: this.calendarId,
      start: toQueryString(start),
      end: toQueryString(end),
      schedule_id: scheduleId,
    });
  }

  /**
   *
   * @param {Date} start
   * @param {Date} end
   * @returns {boolean}
   */
  isEnable(start, end) {
    if (this.error) return true;
    const has = this.events.some((ev) => {
      return (ev.start >= start && ev.start < end) || (ev.end > start && ev.end <= end);
    });

    return !has;
  }

  /**
   *
   * @param {Date} start
   * @param {Date} end
   * @param {string[]} calendarIds
   * @returns {boolean}
   */
  isEnableExcludeId(start, end, calendarIds) {
    if (this.error) return true;
    const has = this.events.some((ev) => {
      if (calendarIds.includes(ev.id)) return false;
      return (ev.start >= start && ev.start < end) || (ev.end > start && ev.end <= end);
    });

    return !has;
  }
}
