import PlansTime from './plansTime';

export default {
  name: 'RoomInfo',
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    setInterval(() => {
      this.$forceUpdate();
    }, 10000);
  },
  render() {
    const list = this.$store.state.events.list;
    const sortStart = list.map((time) => time.start_time);
    const eventSorted = [];
    sortStart.sort((a, b) => {
      const aSt = new Date(a.replace(/-/g, '/'));
      const bSt = new Date(b.replace(/-/g, '/'));
      if (aSt === bSt) return 0;
      return aSt < bSt ? -1 : 1;
    });
    sortStart.forEach((sorting) => {
      const sortedEv = list.find((_this) => sorting === _this.start_time && _this.status === '予約');
      if (sortedEv) eventSorted.push(sortedEv);
    });
    Promise.all([eventSorted]).then((r) => {
      this.$store.commit('updateNextEvent', { type: 'update', data: r[0] });
    });
    return (
      <div>
        <div class="c-shedule__info">
          <dl class="c-shedule__info-list">
            <dt>ただいまの予定</dt>
            {!this.$store.state.nowEvent.start_time && (
              <dd>
                <div class="c-shedule__info-cont is-none">なし</div>
              </dd>
            )}
            {!!this.$store.state.nowEvent.start_time && <PlansTime thisEvent={this.$store.state.nowEvent} />}
          </dl>
          <dl class="c-shedule__info-list">
            <dt>次の予定</dt>
            <dd style={eventSorted.length && 'display: none;'}>{!eventSorted.length && <div class="c-shedule__info-cont is-none">なし</div>}</dd>
            <dd style={!eventSorted.length && 'display: none;'}>{eventSorted.length && <PlansTime thisEvent={eventSorted[0]} />}</dd>
          </dl>
        </div>
      </div>
    );
  },
};
